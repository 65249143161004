body,
fieldset,
ol,
ul,
ul li {
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
  line-height: 1.3;
  background: #fff;
}

ul {
  list-style: none;
}

p {
  padding: 0;
  margin: 0 0 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  font-weight: 400;
  color: #263238;
}

// Play button
$background: #f9f9f9;
$foreground: #2c3e50;

$foreground-light: #34495e;

$size: 15px;
$ratio: 1.2;

$transition-time: 0.3s;

fieldset {
  border: none;
}

button {
  cursor: pointer;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 26px;
}

@media only screen and (min-width: 940px) {
  .container {
    width: 80%;
    margin: 0 auto;
  }
}

h3 {
  font-size: 22px;
  line-height: 38px;
}

p {
  margin-bottom: 15px;
}

header {
  background: #455a64;
  color: #d6d8d7;
  height: 170px;
}

@media only screen and (max-width: 600px) {
  header {
    height: auto;
  }
}

header h1 {
  margin: 0;
  font-family: "Radley", serif;
  font-size: 32px;
}

header h2 {
  margin: 0;
  font-family: "Radley", serif;
  font-size: 28px;
}

header .logo {
  max-width: 170px;
  max-height: 170px;
  padding: 15px 0 0 0;
  margin: 0 auto;
  display: block;
}

.musics a,
.placeholder-wrapper {
  padding: 30px 0 20px;
}

.feeds {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  header .logo {
    float: left;
    margin: 0;
  }

  .feeds {
    float: right;
    margin: 0;
    padding-top: 100px;
  }
}

.feeds li {
  display: inline-block;
}

.feeds li img {
  width: 35px;
  height: 35px;
  margin: 15px 10px;
}

.feeds li img:focus,
.feeds li img:hover {
  opacity: 0.7;
}

@media only screen and (min-width: 600px) {
  .feeds li img {
    width: 35px;
    height: 35px;
  }
}

.logo {
  max-width: 400px;
}

@media (max-width: 600px) {
  .musics {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.musics {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .PianoPodcastDiv {
    display: flex;
    // justify-content: center;
    justify-content: space-between;
    align-items: flex-end;
  }

  .SubscribeLink {
    display: flex;
    justify-content: center;
  }
}


.PianoPodcastDiv {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.PianoPodcastDiv h2 {
  font-size: 26px;
  font-family: 'Radley-Regular';
  // display: inline-block;
}

.VolumeBarContainer {
  // float: right;
  margin-top: 18px;
}

.VolumeBarContainer input {
  height: 30px;
  margin-left: 10px;
}

.musics p {
  width: 100%;
  margin-left: 85px;
}

.musics a {
  width: 100%;
  height: 100%;
  display: block;
}

.musics a:focus,
.musics a:hover {
  background: #d6d8d7;
}

.musics a .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.musics ol li,
.musics ul li {
  position: relative;
  border-bottom: 1px dotted #d6d8d7;
}

.musics ol li:last-child,
.musics ul li:last-child {
  border-bottom: none;
}

.music .music-number {
  display: inline-block;
  font-size: 28px;
  background: #2d363f;
  color: #fff;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 70px;
}

.music .title {
  font-size: 36px;
  width: calc(100% - 85px);
}

.music .date {
  font-style: italic;
}

@media (max-width: 600px) {
  .music .music-number {
    margin: 0 10px 0 0;
    order: 1;
    font-size: 14px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .music .title {
    order: 3;
    margin: 15px 0;
  }

  .music .date {
    order: 2;
    margin-bottom: 0;
    line-height: 40px;
    width: calc(100% - 50px);
  }

  .music .description {
    order: 4;
  }

  .music p {
    margin-left: 0;
  }
}

.music-number {
  display: inline-block;
  font-size: 28px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 70px;
  margin-right: 15px;
}

.placeholder-musics .title {
  height: 25px;
}

.placeholder-musics .date {
  width: 50%;
  height: 10px;
}

.placeholder-musics .description {
  margin-left: 85px;
  width: 85%;
  height: 15px;
}

.placeholder-musics ol li,
.placeholder-musics ul li {
  position: relative;
  border-bottom: 1px dotted #d6d8d7;
}

.placeholder-musics ol li:last-child,
.placeholder-musics ul li:last-child {
  border-bottom: none;
}

@media (max-width: 600px) {
  .placeholder-musics .music-number {
    margin: 0 10px 20px 0;
    order: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .placeholder-musics .title-area {
    margin-bottom: 20px;
  }

  .placeholder-musics .title {
    order: 3;
    margin: 5px 0;
  }

  .placeholder-musics .date {
    order: 2;
    margin-bottom: 0;
    line-height: 40px;
    width: calc(100% - 50px);
  }

  .placeholder-musics .description {
    order: 4;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.heart,
.text-shape {
  height: 15px;
  position: relative;
}

.paging,
footer {
  margin-top: 30px;
}

.text-shape {
  width: 95%;
  text-align: right;
  margin-bottom: 10px;
}

.title-area {
  margin-left: auto;
  display: inline-block;
  vertical-align: baseline;
  width: 80%;
}

.placeholder {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeload;
  animation-name: placeload;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #eee;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(8%, #eee),
      color-stop(18%, #ddd),
      color-stop(33%, #eee));
  background: -webkit-linear-gradient(left, #eee 8%, #ddd 18%, #eee 33%);
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 1200px 104px;
  position: relative;
}

@-webkit-keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.paging {
  text-align: right;
  display: block;
}

.paging .prev {
  margin-right: 20px;
}

.paging a {
  display: inline;
  color: #d6d8d7;
  background: #2d363f;
  padding: 8px 15px;
}

.paging a:focus,
.paging a:hover {
  color: #2d363f;
  background: #d6d8d7;
}

footer {
  clear: both;
  text-align: center;
  color: #d6d8d7;
  background: #455a64;
  padding: 20px 0;
}

footer .legal {
  text-align: center;
  font-size: 12px;
  margin-top: 15px;
}

footer .legal li {
  display: inline-block;
  padding: 0 10px;
  border-left: 1px solid #d6d8d7;
}

footer .legal li:first-child {
  border-left: none;
}

footer a {
  color: #d6d8d7;
}

footer a:focus,
footer a:hover {
  border-bottom: 1px solid #d6d8d7;
}

footer h4 {
  color: #d6d8d7;
}

.heart {
  fill: red;
  top: 2px;
  width: 20px;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }
}

/* Custom Sound Components */
.Sound {
  height: 50px;
  margin: 10px 0px 0px 0px;
  background-color: #b0bec5;
  display: flex;
}

.Sound.active {
  background-color: #d0dce1;
}

/* PlayButtonContainer, SoundTitle, and SoundTimer are all SoundElements*/
.SoundElement {
  display: flex;
  justify-content: center;
  align-content: center;
  white-space: nowrap;
}

.SoundElementText h5 {
  position: relative;
  bottom: 50%;
  font-weight: 550;
  font-family: 'Radley-Regular';
}

.PlayButtonContainer {
  display: flex;
  align-content: center;
  cursor: pointer;
  transform-origin: 50% 50%;
  width: 10%;
  padding: 5px 0px 0px 0px;
}

.SoundTitle {
  width: 61%;
  padding: 10px 0px 0px 20px;
}

.SoundTimer {
  color: #2d363f;
  padding: 10px 5px 0px 0px;
  width: 29%;
  text-align: right;
}

/* PlaySvg centers the svg inside the PlayButton div */
.PlaySvg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* start of progress bar stuff */
.ProgressBarContainer {
  height: 5px;
}

.ProgressBar {
  position: relative;
  height: 100%;
  background-color: #455A64;
}

/* end of progress bar stuff */

/* start of volume bar stuff */
.VolumeBar {
  position: relative;
  height: 100%;
  accent-color: #455A64;
}

/* end of volume bar stuff */

/* start of play button stuff */
.PlayButton g .play {
  transform-origin: 50% 50%;
}

.PlayButton.active g .play {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.PlayButton g .pause {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.PlayButton.active g .pause {
  visibility: visible;
  opacity: 1;
}

.PlayButton g .circle {
  transform-origin: 49% 49%;
  transition: 1s;
}

@keyframes rotate {
  to {
    stroke-dashoffset: 0;
  }
}

//circle1 circumference = 376
#circle1 {
  stroke: black;
  stroke-dasharray: 47;
  stroke-dashoffset: 70;
}

//circle2 circumference = 408
#circle2 {
  stroke: #455A64;
  stroke-dasharray: 51;
  stroke-dashoffset: 77;
}

#circle1.rotated {
  stroke: #455A64;
  transform-origin: 49% 49%;
  transform: rotate(-720deg);
  transition: 1s;
}

#circle2.rotated {
  stroke: black;
  transform-origin: 49% 49%;
  transform: rotate(720deg);
  transition: 1s;
}

/* end of play button stuff */